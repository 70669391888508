import {navigate} from '@reach/router';

export function navigateToOrder(state) {
  navigate('/refill/order/', {
    state: state,
  });
}

export function navigateToHome(state) {
  navigate('/refill/home/', {
    state: state,
  });
}

export function navigateToFill(state) {
  navigate('/refill/fill/', {
    state: state,
  });
}

export function navigateToRefill() {
  navigate('/refill/');
}

export function navigateToTopup() {
  navigate('/refill/topup/');
}

export function navigateToOrderHistory() {
  navigate('/refill/history/');
}

export function navigateToPay() {
  navigate('/refill/pay');
}
