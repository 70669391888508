import React from 'react';
import {navigateToOrder} from '../../helpers/navigation';
import scanbottle from '../../images/app/scanbottle.png';
import underConstruction from '../../images/app/under_construction.png';

const NotFound = (props) => {
  return (
    <div className="notfound d-flex flex-column mt-9 align-items-center">

      <img
        className="notfound-img w-100"
        alt="under construction"
        src={underConstruction}>
      </img>

      <p className="mt-3 mb-0 text-info">Refill</p>
      <img
        onClick={navigateToOrder}
        className="align-self-center notfound-scan"
        alt="Scan Bottle"
        src={scanbottle}
      />

    </div>
  );
};
export default NotFound;
